.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.securityDesktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 0px 16px;
}
@media (max-width: 1024px) {
  .securityDesktop {
    padding: 0px;
  }
}
@media (max-width: 576px) {
  .securityDesktop {
    padding: 0px;
  }
}
.securityDesktopnoPadding {
  padding: 0px;
}
.sslLink {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 77px;
  height: 34px;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .sslLink {
    width: 70px;
    flex-shrink: 0;
  }
}
.ssl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  min-width: 0;
  min-height: 0;
}
.ssl > picture > img {
  object-fit: cover;
}
@media (max-width: 576px) {
  .ssl {
    width: 70px;
  }
}
.smLink {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 84px;
  height: 48px;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .smLink {
    width: 75px;
    flex-shrink: 0;
  }
}
.sm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  min-width: 0;
  min-height: 0;
}
.sm > picture > img {
  object-fit: cover;
}
.trusteLink {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 110px;
  height: 34px;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .trusteLink {
    width: 100px;
    flex-shrink: 0;
  }
}
.truste {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  min-width: 0;
  min-height: 0;
}
.truste > picture > img {
  object-fit: cover;
}
@media (max-width: 576px) {
  .truste {
    height: 100%;
    max-width: auto;
    max-height: none;
    min-height: 0;
  }
}
.socLink {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 84px;
  height: 36px;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .socLink {
    width: 100px;
    flex-shrink: 0;
  }
}
.soc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 84px;
  height: 36px;
  opacity: 0.8;
  flex-shrink: 0;
}
.soc > picture > img {
  object-fit: cover;
}
.securityXs {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 0px 16px;
}
.securityXs > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}
@media (max-width: 1024px) {
  .securityXs {
    padding: 0px;
  }
}
@media (max-width: 576px) {
  .securityXs {
    height: 124px;
    min-height: 124px;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
}
@media (max-width: 576px) {
  .securityXs > :global(.__wab_flex-container) {
    justify-content: center;
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 26px);
    height: calc(100% + 26px);
  }
}
@media (max-width: 576px) {
  .securityXs > :global(.__wab_flex-container) > *,
  .securityXs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .securityXs > :global(.__wab_flex-container) > picture > img,
  .securityXs
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 26px;
  }
}
.top {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-width: 0;
}
.top > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 0;
  margin-left: calc(0px - 48px);
  width: calc(100% + 48px);
}
.top > :global(.__wab_flex-container) > *,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top > :global(.__wab_flex-container) > picture > img,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 48px;
}
.sslLink2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 77px;
  height: 34px;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .sslLink2 {
    width: 87.91px;
    height: 40px;
    flex-shrink: 0;
  }
}
.ssl2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  min-width: 0;
  min-height: 0;
}
.ssl2 > picture > img {
  object-fit: cover;
}
.trusteLink2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 110px;
  height: 34px;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .trusteLink2 {
    width: 125.96px;
    height: 40px;
    flex-shrink: 0;
  }
}
.truste2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  min-width: 0;
  min-height: 0;
}
.truste2 > picture > img {
  object-fit: cover;
}
@media (max-width: 576px) {
  .truste2 {
    height: 100%;
    max-width: auto;
    max-height: none;
    min-height: 0;
  }
}
.bottom {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-width: 0;
}
.bottom > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 0;
  margin-left: calc(0px - 48px);
  width: calc(100% + 48px);
}
.bottom > :global(.__wab_flex-container) > *,
.bottom > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bottom > :global(.__wab_flex-container) > picture > img,
.bottom
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 48px;
}
.smLink2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 84px;
  height: 48px;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .smLink2 {
    width: 104.28px;
    height: 60px;
    flex-shrink: 0;
  }
}
.sm2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  min-width: 0;
  min-height: 0;
}
.sm2 > picture > img {
  object-fit: cover;
}
.socLink2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 84px;
  height: 36px;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 576px) {
  .socLink2 {
    width: 133px;
    height: 55px;
    flex-shrink: 0;
  }
}
.soc2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 133px;
  height: 55px;
  opacity: 0.8;
  flex-shrink: 0;
}
.soc2 > picture > img {
  object-fit: cover;
}
