.divider {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  position: relative;
  min-width: 0;
  border-style: solid;
  border-color: var(--token-7u3Fjf0ZO4pV);
}
