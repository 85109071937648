.placeholder {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  position: relative;
  width: auto;
  justify-self: flex-start;
  cursor: wait;
  border-radius: 4px;
}
