.newsExcerptsItem {
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.newsExcerptsItem > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.newsExcerptsItem > :global(.__wab_flex-container) > *,
.newsExcerptsItem > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.newsExcerptsItem > :global(.__wab_flex-container) > picture > img,
.newsExcerptsItem
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.top {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.top > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.top > :global(.__wab_flex-container) > *,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top > :global(.__wab_flex-container) > picture > img,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.excerptDate {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-sJr9sbZwbyqK);
  display: block;
  align-self: stretch;
  position: relative;
}
.title2 {
  font-family: var(--rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.desc {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.links {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.links > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.links > :global(.__wab_flex-container) > *,
.links > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.links > :global(.__wab_flex-container) > picture > img,
.links > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.download {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  position: relative;
}
