.newsExcerpts {
  background: linear-gradient(rgba(249, 250, 251, 1), rgba(249, 250, 251, 1)),
    url("https://site-assets.plasmic.app/3648a9660ccc75f47048e2740948dc57.jpg")
      0% 0% / 325px 325px repeat,
    rgba(255, 255, 255, 1);
  width: 480px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 0;
  padding: 64px;
}
.newsExcerpts > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.newsExcerpts > :global(.__wab_flex-container) > *,
.newsExcerpts > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.newsExcerpts > :global(.__wab_flex-container) > picture > img,
.newsExcerpts
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .newsExcerpts {
    width: 100%;
    box-shadow: inset 0px 10px 16px -10px #0f2a411a;
    min-width: 0;
    padding: 64px 32px;
  }
}
@media (max-width: 1024px) {
  .newsExcerpts > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .newsExcerpts {
    width: 100%;
    min-width: 0;
    padding: 64px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .newsExcerpts > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.link {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 171px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 170.666px;
  height: 29.831px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.newsProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.content2 {
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.content2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content2 > :global(.__wab_flex-container) > *,
.content2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content2 > :global(.__wab_flex-container) > picture > img,
.content2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.title {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-vF1ggEL4NG72);
  display: block;
  position: relative;
  height: 28px;
  flex-shrink: 0;
}
.news {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.news > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.news > :global(.__wab_flex-container) > *,
.news > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.news > :global(.__wab_flex-container) > picture > img,
.news > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 1024px) {
  .freeBox {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .freeBox > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.newsItemPlaceholder:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
@media (max-width: 1024px) {
  .newsItemPlaceholder:global(.__wab_instance) {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
    display: flex;
  }
}
.newsItem:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
}
@media (max-width: 1024px) {
  .newsItem:global(.__wab_instance) {
    display: flex;
  }
}
.newsDivider:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 1024px) {
  .newsDivider:global(.__wab_instance) {
    flex-shrink: 0;
    display: flex;
  }
}
.button:global(.__wab_instance) {
  height: 40px;
  position: relative;
  flex-shrink: 0;
}
.svg__ctYXi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__eWp0J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
