.resources {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 0;
}
.resources > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.resources > :global(.__wab_flex-container) > *,
.resources > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.resources > :global(.__wab_flex-container) > picture > img,
.resources
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
@media (max-width: 576px) {
  .resources {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 576px) {
  .resources > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-items: stretch;
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 2px);
    height: calc(100% + 2px);
  }
}
@media (max-width: 576px) {
  .resources > :global(.__wab_flex-container) > *,
  .resources > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .resources > :global(.__wab_flex-container) > picture > img,
  .resources
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 12px;
    margin-top: 2px;
  }
}
.accessibility {
  position: relative;
  width: auto;
  white-space: pre;
}
.link__ryei7 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
@media (max-width: 576px) {
  .link__ryei7 {
    white-space: pre;
  }
}
.divider {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  height: 14px;
  width: 1px;
  background: var(--token-gaowRD3VsD3w);
  flex-shrink: 0;
}
.privacyPolicy {
  position: relative;
  width: auto;
}
.link__eVQn {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  white-space: pre;
}
@media (max-width: 576px) {
  .link__eVQn {
    white-space: pre;
  }
}
.divider2 {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 1px;
  background: var(--token-gaowRD3VsD3w);
  align-self: center;
  flex-shrink: 0;
}
.compliance {
  position: relative;
  width: auto;
}
.link__zdsG {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  white-space: pre;
}
