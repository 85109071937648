.newsExcerptsItem {
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.newsExcerptsItem > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.newsExcerptsItem > :global(.__wab_flex-container) > *,
.newsExcerptsItem > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.newsExcerptsItem > :global(.__wab_flex-container) > picture > img,
.newsExcerptsItem
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.top {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.top > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.top > :global(.__wab_flex-container) > *,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top > :global(.__wab_flex-container) > picture > img,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.loadingPlaceholder__up6B:global(.__wab_instance) {
  position: relative;
  width: 60px;
  height: 16px;
  flex-shrink: 0;
}
.freeBox__jc6Y {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  align-self: stretch;
}
.loadingPlaceholder__bwHYf:global(.__wab_instance) {
  position: relative;
  width: 169px;
  height: 20px;
  flex-shrink: 0;
}
.freeBox__k2THm {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__k2THm > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__k2THm > :global(.__wab_flex-container) > *,
.freeBox__k2THm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__k2THm > :global(.__wab_flex-container) > picture > img,
.freeBox__k2THm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.loadingPlaceholder__dIJa:global(.__wab_instance) {
  width: 100%;
  height: 14px;
  min-width: 0;
  flex-shrink: 0;
}
.loadingPlaceholder__wlTCn:global(.__wab_instance) {
  width: 100%;
  height: 14px;
  min-width: 0;
  flex-shrink: 0;
}
.loadingPlaceholder__hDyoY:global(.__wab_instance) {
  width: 240px;
  height: 14px;
  flex-shrink: 0;
}
.links {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.links > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.links > :global(.__wab_flex-container) > *,
.links > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.links > :global(.__wab_flex-container) > picture > img,
.links > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.loadingPlaceholder__rvjg:global(.__wab_instance) {
  width: 74px;
  height: 16px;
  flex-shrink: 0;
}
