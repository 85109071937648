.root {
  display: block;
  position: relative;
  justify-self: flex-start;
}
.dialogCore:global(.__wab_instance) {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-backdrop-filter: blur(10px);
}
.pcls_CmcAd0NOoyMp {
  background: #000000cc;
  outline: none;
}
.freeBox {
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: stretch;
  justify-content: flex-start;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
}
.dialogContent:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.hiddenIfEmptyContainer:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.dialogTitle:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.openProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  left: auto;
  top: auto;
}
.overlayMask {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  width: 100%;
  align-self: flex-start;
  left: auto;
  top: auto;
  height: 100%;
  cursor: pointer;
  min-width: 0;
  min-height: 0;
  border-radius: 4;
}
.stack {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;
  justify-content: flex-start;
  background: none;
  width: auto;
  align-self: flex-end;
  left: auto;
  top: auto;
  height: auto;
  right: auto;
  overflow: auto;
}
.modal {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  background: rgba(255, 255, 255, 1);
  width: 560px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  left: auto;
  top: 0;
  position: fixed;
  overflow: auto;
  right: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .modal {
    width: 100%;
    min-width: 0;
  }
}
.top {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.header {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  min-width: 0;
  padding: 48px 48px 0px;
}
.header > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .header {
    padding: 32px 32px 0px;
  }
}
.left {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  width: auto;
  left: auto;
  top: auto;
  z-index: 1;
  min-height: 0;
}
.left > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.left > :global(.__wab_flex-container) > *,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.left > :global(.__wab_flex-container) > picture > img,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.link {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  position: relative;
  height: auto;
}
.dialogClose:global(.__wab_instance) {
  position: relative;
  top: auto;
  opacity: 0.7;
  max-width: 100%;
  object-fit: cover;
  left: auto;
  z-index: 1;
  right: auto;
  border-radius: 999px;
}
.modalCloseButton:global(.__wab_instance) {
  position: sticky;
  top: 0px;
  left: auto;
  z-index: 1;
  right: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .modalCloseButton:global(.__wab_instance) {
    top: auto;
    right: auto;
    left: auto;
  }
}
.intro {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding: 16px 48px 32px;
}
.intro > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.intro > :global(.__wab_flex-container) > *,
.intro > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.intro > :global(.__wab_flex-container) > picture > img,
.intro > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .intro {
    padding: 16px 32px 0px;
  }
}
.title {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-vF1ggEL4NG72);
  display: block;
  align-self: stretch;
  position: relative;
}
.desc {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.bulletPoints {
  position: relative;
  width: 100%;
  min-width: 0;
}
.ul__hhdqx {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__i4A4J {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.link___3EwYt {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.ul__xuwQe {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__l47Xi {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul__qd75C {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__s3WBc {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul__dd08V {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__j8Jug {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.link__aQw2D {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.ul__g0Jjl {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__sEFhK {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul__pwnkN {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__jkhW4 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.section {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 0px 48px;
}
.section > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.section > :global(.__wab_flex-container) > *,
.section > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.section > :global(.__wab_flex-container) > picture > img,
.section
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section {
    padding: 32px;
  }
}
.text__gYxi {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.bulletPoints2 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.ul__uooNw {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__nBzNu {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul__ccpQi {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__gQgf3 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul___5Pv0 {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__ghPt4 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul__kVs19 {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li__wn1Up {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.ul__bT3L {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.li___6ZCe {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
}
.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  padding: 32px 48px 48px;
}
.footer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.footer > :global(.__wab_flex-container) > *,
.footer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.footer > :global(.__wab_flex-container) > picture > img,
.footer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer {
    width: 100%;
    min-width: 0;
    padding: 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: auto;
}
.svg__ao02 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__hwecz {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-vF1ggEL4NG72);
}
.svg___7OoT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
